





































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'PopUp'
})
export default class PopUp extends Vue {
  // 是否显示弹窗
  @Prop({
    type: Boolean,
    default: false
  })
  isshow!: boolean
  // 当前语言
  @Prop({
    type: String,
    default: 'ug'
  })
  language!: string
  // 标题
  @Prop({
    type: String,
    default: '提示'
  })
  title!: string

  // 文案
  @Prop({
    type: String,
    default: '下载Allo app可观看更多视频'
  })
  content!: string

  // 左按钮
  @Prop({
    type: String,
    default: '取消'
  })
  lfbtn!: string

  // 右按钮
  @Prop({
    type: String,
    default: '确定'
  })
  lrbtn!: string

  handelShut() {
    this.$emit('handelShut')
  }

  deleteComment() {
    this.$emit('deleteComment')
  }
}
