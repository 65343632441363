















































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  getChannelMsgDetail,
  insertComment,
  pageComment,
  likeChannelMsg,
  likeComment,
  deleteComment,
  commentComplaint
} from '@/api'
import {
  appFunc_senToken,
  appFunc_callShareChannelMsg,
  appFunc_RegisterShareCb,
  appFunc_callExit,
  appFunc_callChannelPage
} from '@/utils/bridge'
import DelPopup from '@/components/DelPopup.vue'
import { CommonModule } from '@/store/modules/common'
import moment from 'moment'
import { Toast } from 'vant'
import { PreviewImage } from '@/utils/previewImg'
import { ImagePreview } from 'vant'
@Component({
  name: 'ChannelDetails',
  components: {
    DelPopup
  },
  filters: {
    dateFormat: (value: any, pattern: any) => {
      return moment(value).format(pattern)
    }
  }
})
export default class ChannelDetails extends Vue {
  [x: string]: any
  ruleForm: any = {}

  shareList: any = [
    {
      id: 1,
      title: '转发给朋友',
      url: require('@/assets/icon-friend.png')
    },
    {
      id: 2,
      title: '分享到动态',
      url: require('@/assets/icon-circle.png')
    },
    {
      id: 3,
      title: '微信好友',
      url: require('@/assets/icon-wechat.png')
    },
    {
      id: 4,
      title: '朋友圈',
      url: require('@/assets/icon-wechat-moments.png')
    }
  ]

  userId = 0

  emptyUrl = require('@/assets/Empty_NoData.png')

  isContent = false

  // 留言内容
  content = ''
  // 投诉原因
  type = ''

  htmlUrl = ''

  previewImg = ''

  commentList: any = []

  CommentId = 0

  timeOutEvent = 0

  //   留言弹窗
  isShow = false
  //   更多弹窗
  isMore = false
  //   分享弹窗
  isShare = false
  //   投诉弹窗
  isComplaint = false
  //   长按弹窗
  ispopup = false

  // 删除弹窗
  isDelete = false

  richImages = []

  showImg(e: { target: { tagName: string; src: any } }) {
    if (e.target.tagName == 'IMG') {
      ImagePreview({
        images: this.richImages,
        startPosition: (e.target.src.split('random=')[1] - 1) as any,
        loop: false,
        closeOnPopstate: true //页面回退关闭预览
      })
    }
  }

  // 写留言
  handelDiscuss() {
    this.isShow = true
    this.$nextTick(function() {
      const getFocus: any = this.$refs.getFocus
      getFocus.focus()
    })
  }

  // 返回
  handelBack() {
    appFunc_callExit()
  }

  handelShut() {
    this.isDelete = false
  }

  //  取消
  handelClose() {
    this.isShow = false
    this.content = ''
  }
  //   分享
  handelShare() {
    this.isMore = false
    this.isShare = true
  }

  //   举报
  handelComplaint() {
    this.isMore = false
    this.ispopup = false
    this.isComplaint = true
  }

  handelShutdown() {
    this.type = ''
    this.isComplaint = false
  }
  async created() {
    await this.getToken()

    // 频道详情
    this.getChannelMsgDetail()

    // 评论列表
    this.getPageComment()

    if (this.$route.query.channelMsgId == undefined) {
      appFunc_callExit()
    } else {
      return false
    }
  }

  mounted() {
    // 首次进入
    if (location.hash) {
      let url = window.location.href //获取当前页面的url
      if (url.indexOf('?') != -1) {
        //判断是否存在参数
        url = url.replace(/(\?)[^'"]*/, '') //去除参数
        window.history.pushState({}, '', url)
      }
    } else {
      appFunc_callExit()
    }

    const originalHeight =
      document.documentElement.clientHeight || document.body.clientHeight
    window.onresize = () => {
      //键盘弹起与隐藏都会引起窗口的高度发生变化
      const resizeHeight =
        document.documentElement.clientHeight || document.body.clientHeight
      if (resizeHeight - 0 < originalHeight - 0) {
        //当软键盘弹起，在此处操作
      } else {
        this.isShow = false
        //当软键盘收起，在此处操作
      }
    }
  }

  // 当前语言
  get language() {
    return this.$i18n.locale
  }
  // 切换语言
  getLanguage(language: string) {
    this.$i18n.locale = language || 'zh'
  }

  async getToken() {
    const res = await appFunc_senToken()
    console.log(res, '/////////////')
    CommonModule.setUserId(res.id)
    CommonModule.setToken(res.tokenForJs)
    CommonModule.setUserPhone(res.mobile)
    CommonModule.setUserName(res.name)
  }

  // 跳到频道首页
  handelChannelIndex() {
    console.log('跳转到频道主页', '/////')
    appFunc_callChannelPage(this.ruleForm.channelAccountId)
  }

  //   频道详情
  async getChannelMsgDetail() {
    const { data, code } = await getChannelMsgDetail({
      channelMsgId: this.$route.query.channelMsgId
    })
    if (code == 1001) {
      this.ruleForm = { ...data, share: 0, shareNum: 0 }

      this.isContent = true
      const richImages = PreviewImage(data.msgText)
      this.ruleForm.msgText = richImages[0]
      this.richImages = richImages[1]
      if (
        this.ruleForm.publishType == 2 &&
        location.hash == '#/channel-details'
      ) {
        window.location.href = data.msgText
        return false
      }
    }
  }

  //   频道点赞
  async handelChannelLike() {
    if (this.ruleForm.like) {
      const { code } = await likeChannelMsg({
        channelMsgId: this.$route.query.channelMsgId,
        like: 0
      })
      if (code == 1001) {
        this.getChannelMsgDetail()
      }
    } else {
      const { aplus_queue }: any = window
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['like_channel', 'CLK', {}]
      })
      const { code } = await likeChannelMsg({
        channelMsgId: this.$route.query.channelMsgId,
        like: 1
      })
      if (code == 1001) {
        this.getChannelMsgDetail()
      }
    }
  }

  //   评论列表
  async getPageComment() {
    const { data } = await pageComment({
      channelMsgId: this.$route.query.channelMsgId,
      pageNum: 1,
      pageSize: 100
    })
    this.commentList = data.list
  }

  async deleteComment() {
    const { code } = await deleteComment({
      id: this.userId
    })
    if (code == 1001) {
      this.isDelete = false
      this.getPageComment()
    }
  }

  //   删除评论
  handelDelete(id: any) {
    this.isDelete = true
    this.userId = id
  }

  //   取消点赞
  async handelLike(id: any) {
    const { code } = await likeComment({
      id: id,
      type: -1
    })
    if (code == 1001) {
      this.getPageComment()
    }
  }

  // 点赞
  async handelUnlike(id: any) {
    const { aplus_queue }: any = window
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['comment_like', 'CLK', {}]
    })
    const { code } = await likeComment({
      id: id,
      type: 1
    })
    if (code == 1001) {
      this.getPageComment()
    }
  }

  //   留言
  async handelMessage() {
    const { code, message } = await insertComment({
      channelMsgId: this.ruleForm.channelMsgId,
      content: this.content
    })
    if (code == 1001) {
      const { aplus_queue }: any = window
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['Comment_channel', 'CLK', {}]
      })
      this.isShow = false
      this.content = ''
      this.getPageComment()
      Toast(this.$t('提交成功'))
    } else {
      Toast(message)
    }
  }

  // 举报
  async handelSubmit() {
    const { code, message } = await commentComplaint({
      id: this.CommentId,
      type: this.type
    })
    if (code == 1001) {
      this.isComplaint = false
      this.type = ''
      this.getPageComment()
      Toast(this.$t('举报成功'))
      const { aplus_queue }: any = window
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['report_channel', 'CLK', {}]
      })
    } else {
      Toast(message)
    }
  }

  handelShareto(item: any) {
    if (item.id == 3 || item.id == 4) {
      this.htmlUrl =
        process.env.VUE_APP_channelsharing +
        `?channelMsgId=${this.ruleForm.channelMsgId}&language=${this.language}`
    } else {
      this.htmlUrl =
        process.env.VUE_APP_channeldetails +
        `?channelMsgId=${this.ruleForm.channelMsgId}&language=${this.language}`
    }
    const params = {
      shareType: item.id,
      channelId: this.ruleForm.channelAccountId,
      channelMsgId: this.ruleForm.channelMsgId,
      msgTitle: this.ruleForm.msgTitle,
      msgCover: this.ruleForm.msgCover,
      channelHeadUrl: this.ruleForm.channelHeadUrl,
      htmlUrl: this.htmlUrl
    }
    appFunc_callShareChannelMsg(params)
    appFunc_RegisterShareCb(this.handelShareSuccess)
    const { aplus_queue }: any = window
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['share_channel', 'CLK', { type: item.title }]
    })
  }

  handelShareSuccess(data: string) {
    console.log('频道详情分享成功//////')
    if (data === 'true') {
      this.isShare = false
    } else {
      return false
    }
  }

  //长按事件（起始）
  gtouchstart(item: number) {
    this.timeOutEvent = setTimeout(() => {
      this.longPress(item)
    }, 500)
    return false
  }
  //手释放，如果在500毫秒内就释放，则取消长按事件，此时可以执行onclick应该执行的事件
  showDeleteButton() {
    clearTimeout(this.timeOutEvent) //清除定时器
    if (this.timeOutEvent != 0) {
      //这里写要执行的内容（如onclick事件）
      console.log('点击但未长按')
    }
    return false
  }
  //如果手指有移动，则取消所有事件，此时说明用户只是要移动而不是长按
  gtouchmove() {
    clearTimeout(this.timeOutEvent) //清除定时器
    this.timeOutEvent = 0
  }
  //真正长按后应该执行的内容
  longPress(val: any) {
    console.log(val)
    this.CommentId = val.id
    this.timeOutEvent = 0
    //执行长按要执行的内容，如弹出菜单
    this.ispopup = true
  }
}
